
* {
  font-family: "Inter";
}
 .astro-navbar {
  background-color: #07273c !important;
}
.headset-svg {
  width: 50px;
  height: 50px;
  background-color: #17384e;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
}
.as_logintext {
  color: #ff7010;
}
.as_logo {
  padding: 35px 150px;
  border-right: 1px solid rgb(255 255 255 / 10%);
  display: flex;
  align-items: center;
}
.as_info_detail {
  padding-right: 50px;
  display: flex;
  justify-content: space-between;
}
.as_logintext a {
  color: #fff;
  text-decoration: none;
}
.as_menu_wrapper {
  display: flex !important;
  border-top: 1px solid rgb(255 255 255 / 10%);
  justify-content: flex-end !important;
  padding-right: 150px !important;
  position: relative !important;
  z-index: 1 !important;
}
.list-item {
  color: #fff;
  text-decoration: none;
  /* padding: 23px 0; */
}
.list-item:active {
  color: #fff;
  text-decoration: none;
}
