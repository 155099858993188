.text{color: #fff;}
.text
{
    font-family: "Inter";
    font-size: 16px;
}
.Unorderd_cnt li
{
    font-family: "Inter";
    list-style-type: none;
    line-height: 2;
}
.social-icon
{
    width: 44px;
}
.Unorderd_cnt{
    margin-right: 100px;
}
.Astro-img{
    margin-top: 22px;
}