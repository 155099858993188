
.card_position {
    position: relative;
    top: 60px;
  }
  
  .Content_sec_blog_head {
  color: #ffffff;
  }
  .Content_sec_blog_head h3 {
  color: #ffffff;
    font-size: 20px;
    line-height: 34px;
  }
  .Content_sec_blog_head h3:hover{
  color: #ff7010;
  transition: .6s;
  }
  .Content_sec_blog_head:hover {
  color: #ff7010;
  }
  .Content_sec_blog_para {
  color: #ffffff;
  font-size: 15px;
  }
  .blog_main_bg_section_clr {
  background-color: #08273c;
  height: 100%;
  }
  .Admin_img_and_Comments_main {
      display: flex;
    position: relative;
        bottom: 10px;
    gap: 30px;
  }
  .Admin_img_and_Comments_main span{
  color: #ffffff;
  }
  .img_responsive img {
  height: 291px;
  }
  img.img_responsive {
    height: 291px;
  }
  
  .Blog_main_btn_card button {
     display: inline-block;
    position: relative;
    color: #fff;
    padding: 14px 28px;
    text-align: center;
    clip-path: polygon(10% 0, 90% 0, 100% 50%, 90% 100%, 10% 100%, 0 50%);
    background-color: #ff7010;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 26px;
    left: 235px;
    right: 20px;
  }
  
  .Blog_main_btn_card button:hover {
  background-color: #031d2e;
  color: #ffffff;
  transition: .6s;
  }
  
  #Blog_main_btn_card_tops button {
      display: inline-block;
    position: relative;
    color: #fff;
    padding: 14px 28px;
    text-align: center;
    clip-path: polygon(10% 0, 90% 0, 100% 50%, 90% 100%, 10% 100%, 0 50%);
    background-color: #ff7010;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 235px;
    right: 20px;
    bottom: 4px;
  }
  #Blog_main_btn_card_tops button:hover {
  background-color: #031d2e;
  color: #ffffff;
  transition: .6s;
  }
  #img_responsive_2 {
  position: relative;
  top: 20px;
  }
  
  /*Blog Section responsive */
  
  @media only screen and (max-width:1024px) {
  img.img_responsive {
    height: 240px;
        margin: 0px;
    padding: 20px;
  }
  
  .Blog_main_btn_card button {
  position: relative;
  bottom: 44px;
    left: 144px;;
  }
  .Admin_img_and_Comments_main
  {
  position: relative;
  left: 16px;
  }
  .Content_sec_blog_head h3 {
  position: relative;
  left: 16px;
  }
  .Content_sec_blog_para  {
  position: relative;
  left: 16px;
  }
  }
  
  @media only screen and (max-width:768px) {
  img.img_responsive {
    height: 275px;
        margin: 0px;
    padding: 16px;
  }
  .Blog_main_btn_card button
  {
  right: 0px;
  position: relative;
  left: 200px;
  }
  .blog_main_bg_section_clr {
    background-color: #08273c;
    height: 100%;
  }
  .Admin_img_and_Comments_main
  {
  position: relative;
  left: 16px;
  }
  .Content_sec_blog_head h3 {
  position: relative;
  left: 16px;
  }
  .Content_sec_blog_para  {
  position: relative;
  left: 16px;
  }
  }
  
  @media only screen and (max-width:375px) {
  img.img_responsive {
       height: 240px;
    margin: 10px;
  }
  .Blog_main_btn_card button {
      right: 0px;
    position: relative;
    bottom: 46px;
    left: 180px;
  
  }
  }