.as_service_box {
    background-color: #07273c !important;
    padding: 50px 22px !important;
    margin-top: 30px !important;
    text-align: center;
}
.as_service_box .as_icon {
    width: 80px !important;
    background-color: #10334a;
    height: 80px !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    position: relative;
    transition: all 0.3s linear;
}
.as_service_box .as_icon:after {
    border: 1px dashed #173f59;
    content: '';
    position: absolute;
    left: -10px;
    top: -10px;
    bottom: -10px;
    right: -10px;
    border-radius: 100%;
}
.as_service_box .as_subheading {
    margin: 30px 0 10px;
    padding-bottom: 16px;
    position: relative;
    color: #fff !important;
}
.as_subheading {
    font-size: 22px !important;
    text-transform: capitalize;
}
.as_typo_text {
    font-size: 14px !important;
    line-height: 24px !important;
    color: #fff !important;
}

.as_link {
    text-transform: uppercase !important;
    font-size: 14px !important;
    line-height: 24px !important;
    border-bottom: 1px solid #ff7010 !important;
    color: #ff7010 !important;
    letter-spacing: 0.6px !important;
    text-align: center !important;
    text-decoration: none;
}
.as_service_box:hover .as_icon {
    background-color: #ff7010;
}
.as_service_box:hover .as_icon:after {
    border-color:#ff7010;
    animation: spin 9s infinite linear;
    -webkit-animation: spin 9s infinite linear;
}
.as_service_box .as_icon:after {
    border: 1px dashed #173f59;
    content: '';
    position: absolute;
    left: -10px;
    top: -10px;
    bottom: -10px;
    right: -10px;
    border-radius: 100%;
}

@keyframes spin {
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
}