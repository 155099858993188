.as_whychoose_wrapper {
    padding-top: 80px;
    padding-bottom: 50px;
    background-color: #031d2e;
}

.as_heading {
    font-size: 36px;
    text-transform: capitalize;
    margin: 0;
    padding-bottom: 23px;
    position: relative;
    margin: -8px 0 20px;
}
.as_font14 {
    font-size: 14px;
    line-height: 24px;
}

.as_margin0 {
    margin: 0px;
}
.text-center {
    text-align: center!important;
}
.as_number {
    display: block;
    position: relative;
    width: 130px;
    margin: 0 auto;
}
.as_whychoose_box h4 {
    font-size: 20px;
    margin: 25px 0 35px;
    text-align: center;
    transition: all 0.2s linear;
    color: #fff;
}
.as_whychoose_box .as_number>span {
    color: #fff;
    font-size: 24px;
    font-family: 'Philosopher', sans-serif;
    font-weight: 700;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.chooseus_row{
    align-items: center !important;
}
.chooseus_inner_row{
    display: grid !important;
    grid-template-columns: repeat(3, 1fr) !important;
}