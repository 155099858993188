
.form_field .MuiFormLabel-root{
    font-family: "Philosopher";
}
.as_know_sign_wrapper{
    background-color: #031d2e;
    padding: 80px;
}
.as_sign_form {
    background-color: #07273c;
    padding: 25px 33px 50px;
    width: 100%;
    flex-direction: inherit !important;
    align-items: center !important;
    text-align: left !important;
    padding: 20px !important;
    justify-content: space-evenly !important;
}
.form_field{
    display: flex;
    flex-direction: column;
}
.form-control {
    height: 50px;
    background-color: #17384e;
    border: none;
    outline: none;
    box-shadow: none !important;
    color: #e5f0f5;
    padding: 0 40px 0 30px;
}
.form_field label{
    color: #fff;
}
.form-control input{
    color:#8ba5b6;
}
.as_btn{
    height: 50px;
    background-color: #ff7010 !important;
    font-size: 14px;
    text-transform: uppercase;
    display: inline-flex;
    align-items: center;
    padding: 0 23px;
    position: relative;
    margin-left: 15px;
    color: #e5f0f5 !important;
    border: none !important;
    outline: none;
    width: auto;
    transition: 0.2s linear;
}