.as_service_wrapper {
    background-color:  #031d2e;
    padding: 80px;
}
.as_heading {
    font-size: 36px;
    text-transform: capitalize;
    margin: 0;
    padding-bottom: 23px;
    position: relative;
    color: #fff;
    margin: -8px 0 20px;
    padding-top: 40px;
}

.as_font14 {
    font-size: 14px;
    color: #fff;
    line-height: 24px;
}

.as_padderBottom5 {
    padding-bottom: 5px;
}
.as_service_box .as_subheading {
    position: relative;
}    
.as_service_box .as_subheading:before {
    position: absolute;
    content: "";
    left: 93px;
    right: 0px;
    top: 44px;
    bottom: 0px;
    background-color: #ff7010;
    width: 80px;
    height: 2px;
}   
/* 
.as_margin0{
    position: relative;
}

.as_margin0:before{
    position: absolute;
    content: "";
    left: 0px;
    right: 0px;
    right: 0px;
    bottom: 0px;
    height: 3px;
    width: 104px;
    background-color: #ff7010;
} */