.as_contact_section {
background-image: url(../../assets//Images/all_images/bg4.jpg);
background-repeat: no-repeat;
background-size: cover;
background-position: center;
height: 400px;
}
.as_contact_info .as_heading {
font-size: 32px;
font-weight: 400;
display: inline-block;
}
.as_contact_info>p {
margin-bottom: 36px;
}
.as_contact_info-txt {
text-align: left;
margin-top: 44px;
}
.as_info {
text-align: left;
}
.as_info a {
text-decoration: none;
color: #ffffff;
}
.as_font14 {
font-size: 14px;
line-height: 24px;
}
.as_info_box {
display: flex;
align-items: center;
}
.as_info_box .as_icon {
width: 65px;
background-color: #ff7010;
height: 65px;
display: inline-flex;
align-items: center;
justify-content: center;
border-radius: 100%;
position: relative;
margin-right: 26px;
margin-top: 28px;
}
.as_newsletter_box .form-control
{
border-radius: 0px;
height: 60px;
width: 87.6%;
}
.as_info_box .as_info {
width: calc(100% - 100px);
}
.as_info_box h5 {
color: #ff7010;
font-size: 20px;
margin-bottom: 12px;
font-weight: 400;
}
.as_contact_form {
padding: 46px 50px 50px;
background-color: #17384e;
margin-bottom: -263px;
position: relative;
margin-top: 73px;
}
.as_contact_form .as_subheading {
margin: 0 0 37px;
text-align: left;
color: #ffffff;
font-weight: 400;
}
.as_subheading {
font-size: 22px;
text-transform: capitalize;
}
.form-group {
text-align: initial;
margin: 10px 0;
}
.as_contact_form label {
font-size: 14px;
font-weight: 400;
margin-bottom: 7px;
display: flex;
color: #ffffff;
}
.as_contact_form .form-control {
border: 1px solid rgb(255 255 255 / 10%);
border-radius: 0px;
}
.form-control {
height: 50px;
background-color: #17384e;
border: none;
outline: none;
box-shadow: none !important;
color: #e5f0f5;
padding: 0 40px 0 30px;
/* width: 87.6%; */
}
.form-control-hght
{
height: 132px;
background-color: #17384e;
border: none;
outline: none;
box-shadow: none !important;
color: #e5f0f5;
padding: 0 40px 0 30px;
width: 100%;
border: 1px solid rgb(255 255 255 / 10%);
border-radius: 0px;
overflow: hidden;
}
.App {
text-align: left;
}
.Map_sct {
margin-top: 30px;
}
.as_newsletter_box input[type=text] {
font-size: 16px;
background: #17384e;
border: none;
top: 0px;
left: 0px;
max-width: 100%;
width: 100%;
right: 0px;
}
.form_example button {
padding: 18px;
background: #ff7010;
top: 0px;
right: 46px;
position: absolute;
}
.form_example::after {
content: "";
clear: both;
display: table;
}
.background-img-cont-bg {
background-color: #031d2e;
padding: 62px;
margin-top: -8px;
}
.background-img-cont h1 {
color: #ffffff;
font-weight: 400;
}
.background-img-cont p {
color: #ffffff;
padding-top: 32px;
width: 67%;
}
.contactNews-input {
align-items: center !important;
}
.contactMap_section iframe {
max-width: 100% !important;
width: 100% !important;
}
.bdrBottom {
position: relative;
}
.bdrBottom:before{
content: "";
position: absolute;
left: 0;
bottom: 2px;
right: 0;
width: 100px;
height: 3px;
background-color: #ff7010;
top: 55px;
}
.bdrBottom-letter {
position: relative;
}
.bdrBottom-letter:before{
content: "";
position: absolute;
left: 0;
bottom: 0px;
right: 0;
width: 100px;
height: 3px;
background-color: #ff7010;
top: 64px;
}