* {
    font-family: "Philosopher";
  }
  p{
    font-family: "Inter";
  }
.main_wrapper{
    background-image: url(../../assets//Images/all_images/bg1.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* padding: 130px 310px; */
    overflow: hidden;
}
.light .main_wrapper{
   background-image: url(../../assets/Images/all_images/bg1.light.png);
  }
