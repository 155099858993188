
.as_footer_wrapper{
  background-color: #031d2e;  
}

.as_newsletter_wrapper {
    border-bottom: 1px solid rgb(255 255 255 / 10%);
    text-align: left;
}
.as_padderBottom60 {
    padding-bottom: 60px;
}
.as_heading {
    font-size: 36px;
    text-transform: capitalize;
    margin: 0;
    padding-bottom: 23px;
    position: relative;
    margin: -8px 0 20px;
}
.as_newsletter_box {
    position: relative;
}
.as_newsletter_box .form-control {
    border-radius: 0px;
    height: 60px;
    width: 92%;
}
.as_newsletter_box .as_btn {
    position: absolute;
    right: 5px;
    top: 5px;
    bottom: 5px;
    z-index: 1;
}

.as_btn {
    height: 50px;
    background-color: #ff7010;
    font-size: 14px;
    text-transform: uppercase;
    display: inline-flex;
    align-items: center;
    padding: 0 23px;
    position: relative;
    margin-left: 15px;
    color: var(--white-color);
    border: none !important;
    outline: none;
    width: auto;
    transition: 0.2s linear;
}
.as_newsletter_wrapper .row {
    align-items: center;
}
.as_para{
    font-size: 16px;
    color: #e5f0f5;
}
.as_newsletter_box:after, .as_newsletter_box:before {
    content: '';
    position: absolute;
    left: 100%;
    top: 0;
    bottom: 0;
    border-top: 30px solid transparent;
    border-bottom: 30px solid transparent;
    border-left: 17px solid #17384e;
}
.as_newsletter_box:after {
    right: 100%;
    left: auto;
    border-left: none;
    border-right: 17px solid #17384e;
}
.form-control {
    height: 50px;
    background-color: #17384e;
    border: none;
    outline: none;
    box-shadow: none !important;
    color: #e5f0f5;
    padding: 0 40px 0 30px;
}