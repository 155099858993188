.client_say_bg{
    background-image: url(../../assets/Images/all_images/bg4.jpg);
    padding: 80px;
}
.as_customer_box {
    background-color: #10334a;
    padding: 0 45px 42px;
    position: relative;
    margin: 50px 15px;
    z-index: 1;
}
.text-center {
    text-align: center!important;
}
.as_customer_box:before {
    content: '';
    position: absolute;
    top: 75px;
    transform: translateX(-50%);
    left: 50%;
    width: 86px;
    height: 74px;
    background-image: url(../../assets/Images/all_images/quote2.svg);
    z-index: -1;
}
.as_customer_box .as_customer_img {
    position: relative;
    display: inline-flex;
    margin: -45px 0 34px;
}
.as_customer_box .as_customer_img>img {
    border-radius: 100%;
}
 .slick-slide img {
    display: inline-block;
} 
.as_customer_box .as_customer_img span {
    position: absolute;
    width: 38px;
    height: 38px;
    background-color: #ff7010;
    border-radius: 100%;
    line-height: 32px;
    right: -10px;
    bottom: 0px;
}
.as_customer_box p {
    font-size: 14px;
    line-height: 24px;
}
.as_customer_box h3 {
    font-size: 20px;
    margin: 15px 0 0;
    color: #fff;
}
.as_customer_box p {
    font-size: 14px;
    line-height: 24px;
    color: #fff;
}

.as_margin0 {
    margin: 0px;
}
.slick-prev:before, .slick-next:before {
    color: #ffffff !important;
}
.client_say_bg .slick-dots{
    position: absolute;
    bottom: 0px;
    display: block;
    text-align: center;
    transform: rotate(180deg);
    left: 0;
}
.as_newsletter_box
{
    position: relative;
    display: flex;
    left: 0px;
    right: 0px;
    top: 64px;
}