.about_bg {
  background-image: url(../../assets/Images/all_images/bg2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 80px 0;
}
.as_heading {
  font-size: 36px;
  text-transform: capitalize;
  margin: 0;
  padding-bottom: 23px;
  position: relative;
  margin: -8px 0 20px;
}
.as_about_wrapper p {
  margin-bottom: 32px;
}
.as_contact_expert {
  display: flex;
  background-color: #17384e;
  border-radius: 10px;
  padding: 25px;
  margin-top: 40px;
  align-items: center;
  max-width: 470px;
}
.as_aboutimg::before {
  position: absolute;
  content: "";
  left: 223px;
  bottom: 0;
  border-left: 2px solid #ff7010;
  border-bottom: 2px solid #ff7010;
  width: 90px;
  height: 90px;
}
.as_aboutimg::after {
  position: absolute;
  content: "";
  top: 95px;
  right: 84px;
  background-color: #17384e;
  width: 420px;
  height: 450px;
  z-index: -1;
}
.as_aboutimg img {
  display: inline-block;
}
.as_about_slider .slick-dots {
  position: absolute;
  left: -46px;
  bottom: 32px;
}
.about_bg .slick-arrow {
    display: none !important;
}
.as_aboutimg {
    position: relative;
    z-index: 1;
    padding: 0 0 40px;
    text-align: right;
}
.about_right_text{
text-align: left;
color: #fff;
}
.slick-dots {
    position: absolute;
    bottom: 60px;
    display: block;
    text-align: center;
    transform: rotate(90deg);
    left: -185px;
}
.slick-dots li button:before{
    font-size: 12px;
}
.slick-dots li.slick-active button:before {
    opacity: .75;
    color: #ff7010;
    font-size: 12px;
}
.as_contact_expert {
  display: flex;
  background-color: #17384e;
  border-radius: 10px;
  padding: 25px;
  margin-top: 40px;
  align-items: center;
  max-width: 470px;
}
.as_contact_expert .as_icon {
  width: 65px;
  height: 65px;
  background-color: #ff7010;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 25px;
  position: relative;
}

.as_contact_expert h5 {
  font-size: 18px;
  margin: 0 0 7px 0;
}

.as_contact_expert .as_icon:before {
  position: absolute;
  left: -7px;
  top: -7px;
  bottom: -7px;
  right: -7px;
  border: 1px dashed #ff7010;
  content: '';
  border-radius: 100%;
}
.as_contact_expert:hover .as_icon:before {
  animation: spin 9s infinite linear;
  -webkit-animation: spin 9s infinite linear;
  -moz-animation: spin 9s infinite linear;
}
