.as_orange{
    color: #ff7010;
    font-size: 26px;
    margin: 0 0 5px;
}
.as_banner_detail h1 {
    font-size: 54px;
    line-height: 62px;
    margin: 0 0 13px;
    color: #fff;
}
.as_banner_detail p {
    margin-bottom: 26px;
    color: #fff;
}
.slick-prev:before, .slick-next:before{
    color: #000 !important;
}
.as_btn{
    height: 50px;
    background-color: #ff7010;
    font-size: 14px;
    text-transform: uppercase;
    display: inline-flex;
    align-items: center;
    padding: 0 23px;
    position: relative;
    margin-left: 15px;
    color: #fff;
    border: none !important;
    outline: none;
    text-decoration: none;
    width: auto;
    transition: 0.2s linear;
}
.as_btn:before {
    left: auto;
    right: 100%;
    border-left: none !important;
    border-right: 15px solid #ff7010;
}
.as_btn:after {
    right: auto;
    left: 100%;
    border-left: none;
    /* border-right: 15px solid #ff7010; */
}
.as_btn:after, .as_btn:before {
    content: '';
    position: absolute;
    /* left: 100%; */
    top: 0;
    bottom: 0;
    border-top: 25px solid transparent;
    border-bottom: 25px solid transparent;
    border-left: 15px solid #ff7010;
    transition: 0.2s linear;
}
.as_banner_detail {
    float: left;
    height: 100%;
    min-height: 1px;
    width: 618px;
    text-align: left;
    position: relative;
    right: -2px;
}
.astro_slider_row{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.astro_slider_row .slick-slider {
    width: 50%;
}
.astro_slider_row .slick-prev {
    left: -145px;
}
.astro_slider_row .slick-next {
    right: -750px;
}
