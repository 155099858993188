

*{
margin: 0;
padding: 0;
box-sizing: border-box;
}
.img-responsive {
  width: 100%;
}
/*First section starting here*/

.Main_bg_clr {
background-color: #07273c;
/*	height: 1000px;*/
}
.Main_img_cnt {
position: relative;
}
.Main_img_cnt {  
bottom: 0;
top: 60px;
}
.Main_img_cnt h3 {
position: relative;
color: #ffffff;
}
.Main_img_cnt h3 {
bottom: 0;
top: 60px;
position: absolute;
}
.Residential_Vastu_cnt h3 {
color: #ffffff;
position: relative;
}
.Residential_Vastu_cnt h3 {
display: flex;
right: 0;
bottom: 0;
top: 60px;
}
.Vastu_Shastra_para {
position: relative;
}
.Vastu_Shastra_para {
top: 36px;
color: #ffffff;
margin: 50px 0px 18px 0px;
}
.Vastu_Shastra_para h1 { 
font-size: 32px;
}
.Vastu_p {
margin-bottom: 20px;
font-size: 15px;
line-height: 24px;
}
.mrgn_tp {
  margin-top: 30px;
}
.Tips_img_col {
display: flex;
gap: 68px;
padding-top: 40px;
}
.Tips_img_col_1 {
max-width: 100%;
height: auto;
}
.Tips_img_col_para {
font-size: 15px;
line-height: 24px;
color: #ffffff;
padding: 20px 0px 0px 0px;
}
.Tips_for_home_vastu_hdng{
color: #ffffff;
}
.Tips_for_home_vastu_hdng h4 {
padding: 8px 0px 0px 0px;
font-size: 24px;
}
.Tips_for_home_vastu_hdng_para {
font-size: 15px;
line-height: 24px;
color: #ffffff;
padding: 8px 0px 0px 0px;
}
.ul_li_service_section {
color: #ffffff;
list-style-type: none;
padding: 0 0 6px 23px;
font-size: 14px;
line-height:30px;
position: relative;
letter-spacing: 1px;
}
.pdng_tp
{
  padding-top: 40px;
}
.ul_li_service_section-Sec {
color: #ffffff;
list-style-type: none;
padding: 0 0 6px 23px;
font-size: 16px;
line-height:30px;
position: relative;
letter-spacing: 1px;
}

.ul_li_service_section-Sec :before {
content: '';
position: absolute;
width: 10px;
height: 10px;
background-image: url(../../../astrology-react/src/assets/Images/all_images/arrow.svg);
background-repeat: no-repeat;
left: 0;
margin-top: 10px;
}
.ul_li_service_section :before {
content: '';
position: absolute;
width: 10px;
height: 10px;
background-image: url(../../../astrology-react/src/assets/Images/all_images/arrow.svg);
background-repeat: no-repeat;
left: 0;
margin-top: 10px;
}

/* First section end here */

/*Residential Vastu section starting here*/

.Residential_Vastu_cnt h3 {
font-size: 22px;
}
.ul_li_Residential_section {
color: #ffffff;
list-style-type: none;
position: relative;
top: 80px;    
line-height: 34px;
}
.ul_li_Residential_section span {
  display: inline-block;
  float: right;
}
.ul_li_Residential_section :before {
content: '';
position: absolute;
width: 10px;
height: 10px;
background-image: url(../../../astrology-react/src/assets/Images/all_images/arrow.svg);
background-repeat: no-repeat;
left: 0;
margin-top: 12px;
}
.Commercial_Vastu_cnt {
color: #ffffff;
position: relative;
top: 100px;
}
.Commercial_Vastu_cnt h3 {
font-size: 22px;
}
.Download_app_section_main {
background-color: #ff7010;
max-width: 100%;
position: relative;
top: 105px;
}
.Img-astro_app_store {
padding: 26px;
text-align: center;
}
.Img-astro_app_store img {
margin: 10px;
}
.Img-astro_app_store h3 {
font-size: 22px;
color: #ffffff;
margin: 0px 0px 14px 2px;
padding-top: 4px;
}
.play_store_section {
text-align: center;
}
/*content*/
.Vastu_Shastra_para h1:after {
position: relative;
}
.Vastu_Shastra_para h1:after {
content: '';
position: absolute;
width: 110px;
height: 3px;
background-color: #ff7010;
left: 0;
right: 0;
top: 51px;
bottom: 0;
}
.Residential_Vastu_cnt h3:after {
position: relative;
}
.Residential_Vastu_cnt h3:after {
content: '';
position: absolute;
width: 130px;
height: 3px;
background-color: #ff7010;
left: 3px;
right: 0;
top: 40px;
bottom: 0;
}
.Commercial_Vastu_cnt:after {
position: relative;
}
.Commercial_Vastu_cnt:after {
content: '';
position: absolute;
width: 130px;
height: 3px;
background-color: #ff7010;
left: 3px;
right: 0;
top: 40px;
bottom: 0;
}

/*form section start here*/

.Form_main_sect_bg_co {
background-color: #031d2e;
padding-bottom: 20px;
}
.Form_main_sect_bg_co_cnt {
color: #ffffff;
text-align: center;
padding: 60px 0px 0px;
width: 42%;
margin: 0px auto;
}
.Form_main_sect_bg_co_cnt  h1 {
font-size: 36px;
position: relative;
}
.Form_main_sect_bg_co_cnt  h1:before {
content: '';
position: absolute;
width: 118px;
height: 3px;
background-color: #ff7010;
left: 39%;
right: 0px;
top: 60px;
bottom: 0px;
}
.main_forn_sec_zodic_form{
background-color: #07273c;
height: 150px;
margin-top: 10px;
}
.Form_main_sect_bg_co_cnt  p {
font-size: 15px;
padding-top: 26px;
padding-bottom: 20px;
}
.zodic_form_info label {
color: #fff;
margin-bottom: 15px;
font-size: 20px;
}
.zodic_form_info {
padding: 28px;
}

.form-group button {
  display: inline-block;
  position: relative;
  color: #fff;
  padding: 12px 40px;
  text-align: center;
  clip-path: polygon(10% 0, 90% 0, 100% 50%, 90% 100%, 10% 100%, 0 50%);
  background-color: #ff7010;
  border: none;
  top: 70px;
  cursor: pointer;
display: flex;
justify-content: center;
align-items: center;
}

.form-group button:hover {
  background-color: #031d2e;
  color: #ffffff;
  transition: .6s;
}

#input_clr {
background-color: #17384e;
border: none;
padding: 10px;
color: #51757d;
}
#input_clr:hover{
border: none;
}

/* Login page css starting here */

.Main_login_form_bg_sect {
	background-color: #031b2b;
	height: 939px;
}

.Main_Login_box {
	border: none;
    margin: 0px auto;
    width: 500px;
    padding: 40px;
    background: #17384e;
    border-radius: 10px;
}
.main-box {
	position: relative;
	top: 200px;
}
.Main_Login_box h3 {
	color: #ffffff;
}
.Label_Input_sec 
{
	padding: 10px;
}
#Input_bg_clr
{
	background: #17384e;
    border: 1px solid #244e69;
    padding: 12px;
}
.Icon_cnt_section h3 {
	text-align: right;
}
.underline {
position: relative;
}
.underline:after {
content: '';
background: red;
width: 100px;
height: 2px;
bottom: 0px;
top: 0px;
left: 0px;
right: 0px;
}


.Label_Input_sec_btn button {
  display: inline-block;
  position: relative;
  color: #fff;
  padding: 10px 28px;
  text-align: center;
  clip-path: polygon(10% 0, 90% 0, 100% 50%, 90% 100%, 10% 100%, 0 50%);
  background-color: #ff7010;
  border: none;
  left: 150px;
  top: 10px;
  cursor: pointer;
display: flex;
justify-content: center;
align-items: center;
}

.Label_Input_sec_btn button:hover {
  background-color: #031d2e;
  color: #ffffff;
  transition: .6s;
}






.Label_Input_sec_cnt  {
	    position: relative;
    top: 40px;
    text-align: center;
    color: #ffffff;
    margin-bottom: 40px;
}

.Label_Input_sec_cnt h6 span a {
	color: #ff7010;
  text-decoration: none;
}

/* sign up page css start here */
.Main_Login_form_bg_sect {
	background-color: #031b2b;
	height: 939px;
}

.Main_Login_box {
	border: none;
    margin: 0px auto;
    width: 500px;
    padding: 40px;
    background: #17384e;
    border-radius: 10px;
}
.main-box {
	position: relative;
	top: 200px;
}
.Main_Login_box h3 {
	color: #ffffff;
}
.Label_Input_sec_sign_up
{
	padding: 10px;
}
#Input_bg_clr_sign_up
{
	background: #17384e;
    border: 1px solid #244e69;
    padding: 12px;
}
.Icon_cnt_section_sign_up h3 {
	text-align: right;
}
.underline {
position: relative;
}
.underline:after {
content: '';
background: red;
width: 100px;
height: 2px;
bottom: 0px;
top: 0px;
left: 0px;
right: 0px;
}


.Label_Input_sec_btn_sign_up button {
  display: inline-block;
  position: relative;
  color: #fff;
  padding: 10px 28px;
  text-align: center;
  clip-path: polygon(10% 0, 90% 0, 100% 50%, 90% 100%, 10% 100%, 0 50%);
  background-color: #ff7010;
  border: none;
  left: 150px;
  top: 10px;
  cursor: pointer;
display: flex;
justify-content: center;
align-items: center;
}

.Label_Input_sec_btn_sign_up button:hover {
  background-color: #031d2e;
  color: #ffffff;
  transition: .6s;
}


.Label_Input_sec_cnt_sign_up  {
	    position: relative;
    top: 40px;
    text-align: center;
    color: #ffffff;
    margin-bottom: 40px;
}

.Label_Input_sec_cnt_sign_up h6 span a {
	color: #ff7010;
  text-decoration: none;
}

/* Blog page css start here */

.card_position {
  position: relative;
  top: 60px;
}

.Content_sec_blog_head {
color: #ffffff;
}
.Content_sec_blog_head h3 {
color: #ffffff;
  font-size: 20px;
  line-height: 34px;
}
.Content_sec_blog_head h3:hover{
color: #ff7010;
transition: .6s;
}
.Content_sec_blog_head:hover {
color: #ff7010;
}
.Content_sec_blog_para {
color: #ffffff;
font-size: 15px;
}
.blog_main_bg_section_clr {
background-color: #08273c;
height: 1150px;
}
.Admin_img_and_Comments_main {
    display: flex;
  position: relative;
      bottom: 10px;
  gap: 30px;
}
.Admin_img_and_Comments_main span{
color: #ffffff;
}
.img_responsive img {
height: 291px;
}
img.img_responsive {
  height: 291px;
}

.Blog_main_btn_card button {
   display: inline-block;
  position: relative;
  color: #fff;
  padding: 14px 28px;
  text-align: center;
  clip-path: polygon(10% 0, 90% 0, 100% 50%, 90% 100%, 10% 100%, 0 50%);
  background-color: #ff7010;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 26px;
  left: 235px;
  right: 20px;
}

.Blog_main_btn_card button:hover {
background-color: #031d2e;
color: #ffffff;
transition: .6s;
}

#Blog_main_btn_card_tops button {
    display: inline-block;
  position: relative;
  color: #fff;
  padding: 14px 28px;
  text-align: center;
  clip-path: polygon(10% 0, 90% 0, 100% 50%, 90% 100%, 10% 100%, 0 50%);
  background-color: #ff7010;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 235px;
  right: 20px;
  bottom: 4px;
}
#Blog_main_btn_card_tops button:hover {
background-color: #031d2e;
color: #ffffff;
transition: .6s;
}
#img_responsive_2 {
position: relative;
top: 20px;
}
